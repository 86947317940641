import { render, staticRenderFns } from "./CourseTips.vue?vue&type=template&id=55866359&scoped=true"
import script from "./CourseTips.vue?vue&type=script&lang=js"
export * from "./CourseTips.vue?vue&type=script&lang=js"
import style0 from "./CourseTips.vue?vue&type=style&index=0&id=55866359&prod&lang=css"
import style1 from "./CourseTips.vue?vue&type=style&index=1&id=55866359&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55866359",
  null
  
)

export default component.exports