import maintenance from './maintenance/index';
import message from './message/index';

export default {
  namespaced: true,
  modules: {
    maintenance,
    message,
  },
};
