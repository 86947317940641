<template>
  <span class="header">
    <span class="left">
      {{ attendee.courseNameDisplay }}
    </span>
    <span class="right">
      <b-nav pills>
        <b-nav-item-dropdown
          v-if="
            this.attendeeTerminalAddresses &&
              this.attendeeTerminalAddresses.length > 1
          "
          text="Exam Interface"
          toggle-class="nav-link-custom"
          right
        >
          <b-dropdown-item v-on:click="interfaceRemoteDesktop">
            Remote Desktop
          </b-dropdown-item>
          <b-dropdown-item v-on:click="interfaceRemoteTerminal">
            Remote Terminal
          </b-dropdown-item>
          <b-dropdown-item v-on:click="toggleInstruction">
            Info / Help
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          text="Exam Controls"
          toggle-class="nav-link-custom"
          right
        >
          <b-dropdown-item v-on:click="openDashboardPage">
            Dashboard
          </b-dropdown-item>
          <b-dropdown-item
            v-if="showSessionRestartLink"
            v-on:click="restartSession"
          >
            Restart Session</b-dropdown-item
          >
          <b-dropdown-item v-if="showSessionStopLink" v-on:click="stopSession">
            Solutions & Score
          </b-dropdown-item>
          <b-dropdown-item v-if="showAnswerLink" v-on:click="openAnswersPage">
            Solutions
          </b-dropdown-item>
          <b-dropdown-item v-if="showScoreLink" v-on:click="openScorePage">
            Score
          </b-dropdown-item>
          <b-dropdown-item
            v-if="attendee.course.type.includes('lfct')"
            v-on:click="toggleNotepad"
            >Notepad</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          text="Exam Information"
          toggle-class="nav-link-custom"
          right
        >
          <b-dropdown-item v-on:click="toggleInstruction">
            Instructions
          </b-dropdown-item>
          <b-dropdown-item v-on:click="openSlackPage">
            Discussions
            <font-awesome-icon :icon="['fab', 'slack']" size="1x" class="" />
          </b-dropdown-item>
          <b-dropdown-item v-on:click="openIssuePage">
            Help / Support
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
    </span>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  name: 'Header',
  props: {
    attendee: {
      required: true,
    },
  },
  data: function() {
    return {
      showNotepad: false,
      startedSession: false,
      attendeeDataDidLoadAtLeastOnce: false,
    };
  },
  computed: {
    ...mapGetters({
      sessionStartIsLoading: 'attendee/sessionStart/isLoading',
      sessionStopIsLoading: 'attendee/sessionStop/isLoading',
      sessionRestartIsLoading: 'attendee/restart/isLoading',
      attendeeTerminalAddresses: 'attendee/run/attendeeTerminalAddresses',
    }),
    featureAttendeeRestartEnabled() {
      let func = this.$store.getters['feature/list/featureByName'];
      if (func('attendee_restart')) {
        return func('attendee_restart')['enabled'];
      }
      return false;
    },
    terminalActive: function() {
      return this.attendee.terminal.status === 'active';
    },
    terminalInactive: function() {
      return this.attendee.terminal.status === 'inactive';
    },
    sessionMinutesLeft: function() {
      return this.attendee.sessionMinutesLeft;
    },
    sessionHasStarted: function() {
      return this.attendee.sessionStarted;
    },
    showSessionStopLink: function() {
      return (
        !this.sessionStopIsLoading &&
        !this.sessionRestartIsLoading &&
        this.sessionHasStarted &&
        this.sessionMinutesLeft > 0 &&
        this.terminalActive
      );
    },
    showSessionRestartLink: function() {
      return (
        this.sessionHasStarted &&
        this.terminalActive &&
        !this.sessionRestartIsLoading
      );
    },
    showAnswerLink: function() {
      return (
        ((this.sessionHasStarted && this.terminalActive) ||
          this.terminalInactive) &&
        this.sessionMinutesLeft <= 0
      );
    },
    showScoreLink: function() {
      return (
        this.sessionMinutesLeft <= 0 &&
        this.sessionHasStarted &&
        this.attendee &&
        this.attendee.scores &&
        this.attendee.scores.length &&
        this.attendee.terminal.status === 'active'
      );
    },
  },
  methods: {
    ...mapActions({
      getFeatures: 'feature/list/getFeatures',
    }),
    toggleNotepad: function() {
      this.$store.dispatch('attendee/run/notepadShowToggle');
    },
    toggleChat: function() {
      this.$store.dispatch('attendee/run/chatShowToggle');
    },
    toggleInstruction: function() {
      this.$store.dispatch('attendee/run/instructionShowToggle');
    },
    interfaceRemoteDesktop: function() {
      this.$store.dispatch('attendee/run/setAttendeeTerminalAddressPos', 0);
    },
    interfaceRemoteTerminal: function() {
      this.$store.dispatch('attendee/run/setAttendeeTerminalAddressPos', 1);
      Vue.prototype.$flashStorage.flash(
        'The real exam only has the Remote Desktop, not the Remote Terminal',
        'warning',
        {
          timeout: 999999,
        }
      );
    },
    stopSession: function() {
      let options = {
        html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
        loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
        reverse: false, // switch the button positions (left to right, and vise versa)
        okText: 'Yes',
        cancelText: 'No',
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        type: 'soft', // coming soon: 'soft', 'hard'
        verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
        verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
        backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
        customClass: '', // Custom class to be injected into the parent node for the current dialog instance
      };

      let that = this;
      this.$dialog
        .confirm(
          'Would you like to end your simulator session early and see the solutions and your score? This will just stop the countdown, you will still have environment access.',
          options
        )
        .then(function() {
          that.$store.dispatch('attendee/sessionStop/sessionStop', [
            that.attendee['@id'],
            that.attendee['uuid'],
          ]);
        })
        .catch(function() {});
    },
    openAnswersPage: function() {
      let routeData = this.$router.resolve({
        name: 'AttendeeSolution',
      });
      window.open(routeData.href, '_blank');
    },
    openScorePage: function() {
      let routeData = this.$router.resolve({
        name: 'AttendeeScore',
      });
      window.open(routeData.href, '_blank');
    },
    openIssuePage: function() {
      let routeData = this.$router.resolve({
        name: 'FaqSessionRuntime',
      });
      window.open(routeData.href, '_blank');
    },
    openSlackPage: function() {
      window.open('https://killer.sh/slack', '_blank');
    },
    openDashboardPage: function() {
      this.$router.push({ name: 'Dashboard' });
    },
    restartSession: function() {
      if (this.featureAttendeeRestartEnabled) {
        let options = {
          html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
          loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
          reverse: false, // switch the button positions (left to right, and vise versa)
          okText: 'Yes',
          cancelText: 'No',
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          type: 'soft', // coming soon: 'soft', 'hard'
          verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
          verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
          clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
          backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
          customClass: '', // Custom class to be injected into the parent node for the current dialog instance
        };

        let that = this;
        this.$dialog
          .confirm(
            'Would you like to restart your simulator session? You will lose everything you configured and a fresh environment will be created.',
            options
          )
          .then(function() {
            that.sessionRestartIsHappening = true;
            setTimeout(function() {
              that.sessionRestartIsHappening = false;
            }, 15000);
            that.$store.dispatch('attendee/restart/restart', [
              that.attendee['@id'],
              that.attendee['uuid'],
            ]);
            that.startedSession = false;
          })
          .catch(function() {});
      } else {
        let options = {
          html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
          loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
          reverse: false, // switch the button positions (left to right, and vise versa)
          okText: 'Ok',
          cancelText: 'Cancel',
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          type: 'basic', // coming soon: 'soft', 'hard'
          verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
          verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
          clicksCount: null, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
          backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
          customClass: '', // Custom class to be injected into the parent node for the current dialog instance
        };

        this.$dialog
          .confirm(
            'Restarting environments IS NOT POSSIBLE at the moment, please try again later. Also check https://killer.sh/status',
            options
          )
          .then(function() {})
          .catch(function() {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
::v-deep .nav-pills .show > .nav-link {
  background-color: #2e99ce !important;
}

::v-deep .nav-pills .nav-link {
  border-radius: 0;
}

.header {
  background-color: hsl(215deg 74% 23%);
  height: 40px !important; // also change in Notepad->Draggable AND Run.vue->getWrapperStyle
  display: block;
  .left {
    float: left;
    color: #f9fefe;
    margin: 7px 6px;
    font-weight: bolder;
  }
  .right {
    float: right;
  }
  .nav {
    padding-right: 13px;
    .nav-item {
      width: 80px;
      height: 30px;
      z-index: 99999 !important;
      background-color: #1c7396;
      margin: 5px 5px 0 0;
      &.dropdown {
        width: 150px;
      }
      .nav-link,
      ::v-deep .dropdown-toggle {
        height: 30px;
        font-size: 15px;
        color: #f9fefe;
        padding: 3px 0;
        text-align: center;
      }

      ::v-deep .dropdown-menu {
        background-color: #1c7396;
        transform: translate3d(0px, 30px, 0px) !important;
        width: 150px;
        min-width: 0;
        border-radius: 0;

        li a {
          color: #f9fefe;
          font-size: 15px;
          padding-left: 20px;
        }

        li a:hover {
          background-color: #2e99ce !important;
        }
      }
    }
  }
}
</style>
