var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"terminal",class:{ lfct: _vm.attendee.course.type.includes('lfct') }},[(_vm.attendee.terminal && !_vm.shouldWeWaitForAttendeeData())?_c('span',[(_vm.showIframe && _vm.attendeeTerminalAddress)?_c('span',[(_vm.attendee.course.type.includes('lfct'))?_c('TerminalLfct',{attrs:{"attendee":_vm.attendee,"attendeeTerminalAddressPassed":_vm.attendeeTerminalAddress}}):_c('TerminalDefault',{attrs:{"attendee":_vm.attendee,"attendeeTerminalAddressPassed":_vm.attendeeTerminalAddress}})],1):(
        _vm.attendee.terminal.status === 'activating' ||
          (_vm.attendee.terminal.status === 'active' &&
            this.sessionRestartIsHappening)
      )?_c('span',[_c('div',{staticStyle:{"padding-top":"10px"}}),_c('div',[_vm._v(" Creating and initializing environment. "),(_vm.attendee.terminal.estimatedMinutesTillActive)?_c('span',[_vm._v(" This will take around "+_vm._s(_vm.attendee.terminal.estimatedMinutesTillActive)+" minutes. ")]):_vm._e()]),(_vm.attendee.terminal.estimatedTimeTillActiveTakesLongerThanNormal)?_c('TerminalActivationDelayMessage'):_vm._e(),_c('br'),_c('Spinner',{attrs:{"color":"white"}})],1):(
        _vm.attendee.terminal.status === 'suspend' ||
          _vm.attendee.terminal.status === 'resume'
      )?_c('span',[_c('div',{staticStyle:{"padding-top":"10px"}}),_c('p',[_vm._v(" Reactivating your environment. "),(_vm.attendee.terminal.estimatedMinutesTillActive)?_c('span',[_vm._v(" This will take around "+_vm._s(_vm.attendee.terminal.estimatedMinutesTillActive)+" minutes. ")]):_vm._e(),(
            _vm.attendee.terminal.estimatedTimeTillActiveTakesLongerThanNormal
          )?_c('TerminalActivationDelayMessage'):_vm._e()],1),_c('Spinner',{attrs:{"color":"white"}})],1):(_vm.attendee.terminal.expired)?_c('span',[_c('p',[_vm._v(" Expired ")])]):(_vm.inactivityConfirmationVisibleHideIframe)?_c('span',[_c('p',[_vm._v(" Suspended ")])]):_c('span',[_c('div',{staticStyle:{"padding-top":"10px"}}),(!_vm.terminalActive)?_c('p',[_vm._v(" Environment status: "+_vm._s(_vm.attendee.terminal.statusText)+" ")]):_vm._e(),(_vm.terminalActive)?_c('span',[(_vm.showSessionStartLink)?_c('button',{staticClass:"btn card-cta-secondary card-cta center simulator-start mt-5",staticStyle:{"margin":"auto auto","height":"50px"},on:{"click":function($event){return _vm.startSession()}}},[_vm._v(" Start the countdown ")]):_vm._e(),(_vm.sessionStartIsLoading)?_c('span',[_vm._v(" Countdown is starting... ")]):_vm._e()]):_vm._e()]),(_vm.attendee.terminal.error)?_c('span',[_vm._m(0)]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Well, an error occurred")]),_vm._v(". This should not have happened! "),_c('br'),_vm._v(" We created another session for you to start, head to your dashboard. "),_c('br'),_vm._v("Please contact support if there are any more issues. ")])}]

export { render, staticRenderFns }