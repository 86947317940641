<template>
  <div
    class="terminal"
    :class="{ lfct: attendee.course.type.includes('lfct') }"
  >
    <span v-if="attendee.terminal && !shouldWeWaitForAttendeeData()">
      <span v-if="showIframe && attendeeTerminalAddress">
        <TerminalLfct
          v-if="attendee.course.type.includes('lfct')"
          v-bind:attendee="attendee"
          v-bind:attendeeTerminalAddressPassed="attendeeTerminalAddress"
        ></TerminalLfct>
        <TerminalDefault
          v-else
          v-bind:attendee="attendee"
          v-bind:attendeeTerminalAddressPassed="attendeeTerminalAddress"
        ></TerminalDefault>
      </span>
      <span
        v-else-if="
          attendee.terminal.status === 'activating' ||
            (attendee.terminal.status === 'active' &&
              this.sessionRestartIsHappening)
        "
      >
        <div style="padding-top: 10px"></div>

        <div>
          Creating and initializing environment.
          <span v-if="attendee.terminal.estimatedMinutesTillActive">
            This will take around
            {{ attendee.terminal.estimatedMinutesTillActive }} minutes.
          </span>
        </div>

        <TerminalActivationDelayMessage
          v-if="attendee.terminal.estimatedTimeTillActiveTakesLongerThanNormal"
        ></TerminalActivationDelayMessage>

        <br />
        <Spinner color="white"></Spinner>
      </span>
      <span
        v-else-if="
          attendee.terminal.status === 'suspend' ||
            attendee.terminal.status === 'resume'
        "
      >
        <div style="padding-top: 10px"></div>

        <p>
          Reactivating your environment.
          <span v-if="attendee.terminal.estimatedMinutesTillActive">
            This will take around
            {{ attendee.terminal.estimatedMinutesTillActive }} minutes.
          </span>

          <TerminalActivationDelayMessage
            v-if="
              attendee.terminal.estimatedTimeTillActiveTakesLongerThanNormal
            "
          ></TerminalActivationDelayMessage>
        </p>
        <Spinner color="white"></Spinner>
      </span>
      <span v-else-if="attendee.terminal.expired">
        <p>
          Expired
        </p>
      </span>
      <span v-else-if="inactivityConfirmationVisibleHideIframe">
        <p>
          Suspended
        </p>
      </span>
      <span v-else>
        <div style="padding-top: 10px"></div>

        <p v-if="!terminalActive">
          Environment status: {{ attendee.terminal.statusText }}
        </p>

        <span v-if="terminalActive">
          <button
            style="margin: auto auto; height: 50px"
            class="btn card-cta-secondary card-cta center simulator-start mt-5"
            v-on:click="startSession()"
            v-if="showSessionStartLink"
          >
            Start the countdown
          </button>
          <span v-if="sessionStartIsLoading">
            Countdown is starting...
          </span>
        </span>
      </span>
      <span v-if="attendee.terminal.error">
        <p>
          <strong>Well, an error occurred</strong>. This should not have
          happened! <br />
          We created another session for you to start, head to your dashboard.
          <br />Please contact support if there are any more issues.
        </p>
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import TerminalDefault from '@/components/attendee/run/TerminalDefault';
import TerminalLfct from '@/components/attendee/run/lfct/Terminal';
import TerminalActivationDelayMessage from './TerminalActivationDelayMessage.vue';

export default {
  name: 'Terminal',
  components: {
    TerminalActivationDelayMessage,
    Spinner,
    TerminalDefault,
    TerminalLfct,
  },
  data: function() {
    return {
      sessionRestartIsHappening: false,
      attendeeTerminalAddressTryCount: 0,
      attendeeDataDidLoadAtLeastOnce: false,
      startedSession: false,
    };
  },
  props: {
    attendee: {
      required: true,
    },
  },
  created() {
    this.$store.dispatch('attendee/run/setAttendeeTerminalAddresses', []);
    this.$store.dispatch('attendee/run/setAttendeeTerminalAddressPos', null);
  },
  computed: {
    ...mapGetters({
      sessionStartIsLoading: 'attendee/sessionStart/isLoading',
      sessionStopIsLoading: 'attendee/sessionStop/isLoading',
      sessionRestartIsLoading: 'attendee/restart/isLoading',
      attendeeGetIsLoading: 'attendee/get/isLoading',
      inactivityConfirmationVisibleHideIframe:
        'attendee/run/inactivityConfirmationVisibleHideIframe',
      attendeeTerminalAddress: 'attendee/run/attendeeTerminalAddress',
      attendeeTerminalAddresses: 'attendee/run/attendeeTerminalAddresses',
    }),
    showIframe: function() {
      return (
        this.attendee.terminal &&
        this.terminalActive &&
        this.attendee.sessionStarted &&
        !this.sessionRestartIsHappening &&
        !this.inactivityConfirmationVisibleHideIframe &&
        this.attendeeDataDidLoadAtLeastOnce
      );
    },
    terminalActive: function() {
      return this.attendee.terminal.status === 'active';
    },
    showSessionStartLink: function() {
      return !this.startedSession && !this.sessionStartIsLoading;
    },
  },
  watch: {
    showIframe(newValue) {
      if (newValue) {
        let urls = this.attendee.terminal.clusterTerminalProxyAddresses;
        this.$store.dispatch('attendee/run/setAttendeeTerminalAddresses', urls);
        this.$store.dispatch('attendee/run/setAttendeeTerminalAddressPos', 0);
      } else {
        this.$store.dispatch(
          'attendee/run/setAttendeeTerminalAddressPos',
          null
        );
      }
    },
    attendeeGetIsLoading(newValue) {
      if (!newValue) {
        this.attendeeDataDidLoadAtLeastOnce = true;
      }
    },
  },
  methods: {
    shouldWeWaitForAttendeeData: function() {
      return !this.attendeeDataDidLoadAtLeastOnce;
    },
    startSession: function() {
      this.$store.dispatch('attendee/sessionStart/sessionStart', [
        this.attendee['@id'],
        this.attendee['uuid'],
      ]);
      this.startedSession = true;
      setTimeout(() => {
        this.startedSession = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';

.simulator-start:hover {
  background-color: #2e99ce !important;
}

.terminal {
  height: 100%;
  background-color: #2b2b2b;
  color: #d2d2d2;
  overflow: hidden;

  p {
    padding: 5px;
  }

  text-align: center;
}

.spring-spinner {
  margin: 0 auto;
}
</style>
