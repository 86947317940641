<template>
  <div class="root">
    <div v-if="isLoading" style="padding-top: 100px">
      <Spinner></Spinner>
    </div>
    <div v-else>
      <span v-if="course && course.answersHtml">
        <span v-html="course.answersHtml[courseVersion]"></span>
      </span>
    </div>
  </div>
</template>

<script>
import 'markdown-it-vue/dist/markdown-it-vue.css';
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';

export default {
  components: {
    Spinner,
  },
  name: 'CourseAnswers',
  props: {
    courseVersion: String,
    // either pass courseUuid
    courseUuid: String,
    // OR course
    coursePassed: Object,
    fetchCourses: {
      type: Boolean,
      default: true,
    },
  },
  mounted: function() {
    if (!this.coursePassed && this.fetchCourses) {
      this.getCourses();
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'course/list/isLoading',
    }),
    course() {
      if (this.coursePassed) {
        return this.coursePassed;
      } else {
        let func = this.$store.getters['course/list/courseByUuid'];
        return func(this.courseUuid);
      }
    },
  },
  methods: {
    ...mapActions({
      getCourses: 'course/list/getCourses',
    }),
  },
};
</script>

<style lang="css">
code {
  color: black !important;
}
</style>

<style lang="scss" scoped>
.root {
  background-color: white;
  min-height: 100vh;
}
.question {
  margin-bottom: 50px;
}
</style>
