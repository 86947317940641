<template>
  <div :class="classOuter">
    <button
      class="btn card-cta-primary"
      v-on:click="activateAttendee(courseVersionChoices[0])"
    >
      Activate {{ courseVersionChoices[0] }}
    </button>
    <br />
    <small class="mt-2 ml-2" v-if="showPreviousActivations">
      Previous activations:
      <span v-if="courseVersionChoices[1] && courseVersionChoices[1].length">
        <ul>
          <li v-for="(item, index) in courseVersionChoices[1]" :key="index">
            {{ item }}
          </li>
        </ul>
      </span>
      <span v-else>(none)</span>
    </small>
  </div>
</template>

<script>
export default {
  name: 'AttendeeActivateVersionChoice',
  props: {
    courseVersionChoices: {
      required: true,
    },
    classOuter: {},
    activateAttendee: {
      type: Function,
      required: true,
    },
    showPreviousActivations: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
</style>
