import Oauth from '../components/authentication/Oauth';
import CkadSimulatorAbout from '../components/static/CkadSimulatorAbout';
import CkaSimulatorAbout from '../components/static/CkaSimulatorAbout';
import CksSimulatorAbout from '../components/static/CksSimulatorAbout';
import LfctSimulatorAbout from '../components/static/LfctSimulatorAbout';
import LfcsSimulatorAbout from '../components/static/LfcsSimulatorAbout';
import Home from '../components/static/Home';
import Legal from '../components/static/Legal';
import Login from '../components/authentication/Login';
import Logout from '../components/authentication/Logout';
import AttendeeView from '../components/attendee/View';
import Solution from '../components/attendee/solution/Solution';
import SolutionPublicViaClusterUuid from '../components/attendee/solution/SolutionPublicViaClusterUuid';
import Score from '../components/attendee/score/Score';
import ScorePublicViaClusterUuid from '../components/attendee/score/ScorePublicViaClusterUuid';
import UserProfile from '../components/user/AccountEdit';
import UserConsent from '../components/user/UserConsent';
import Dashboard from '../components/dashboard/Dashboard';
import Faq from '../components/static/Faq';
import Pricing from '../components/static/Pricing';
import CoursePreview from '../components/course/CoursePreview';
import CourseTipsAttendee from '../components/course/CourseTipsAttendee';
import OrderCheckout from '../components/order/OrderCheckout';
import OrderSelectCreate from '../components/order/OrderSelectCreate';
import Terms from '../components/static/Terms';
import Slack from '../components/static/Slack';
import Support from '../components/support/Support';
import AccessRequestGeneral from '../components/accessRequest/General';
import AccessRequestLinuxFoundation from '../components/accessRequest/LinuxFoundation';
import AuthenticationLinuxFoundation from '../components/authentication/LinuxFoundation';
import Redirect from '../components/redirect/Redirect';
import Status from '../components/support/Status';
import KillercodaAccess from '../components/accessRequest/KillercodaAccess';

export default [
  {
    name: 'Authentication',
    path: '/auth/:data(.*)',
    component: Oauth,
  },
  { name: 'Home', path: '/', component: Home },
  { name: 'Dashboard', path: '/dashboard', component: Dashboard },
  { name: 'Login', path: '/login', component: Login, props: true },
  { name: 'Logout', path: '/logout', component: Logout },
  { name: 'CkadSimulatorAbout', path: '/ckad', component: CkadSimulatorAbout },
  { name: 'CkaSimulatorAbout', path: '/cka', component: CkaSimulatorAbout },
  { name: 'CksSimulatorAbout', path: '/cks', component: CksSimulatorAbout },
  { name: 'LfctSimulatorAbout', path: '/lfct', component: LfctSimulatorAbout },
  { name: 'LfcsSimulatorAbout', path: '/lfcs', component: LfcsSimulatorAbout },
  { name: 'Legal', path: '/legal', component: Legal },
  { name: 'Faq', path: '/faq', component: Faq },
  { name: 'Pricing', path: '/pricing', component: Pricing },
  { name: 'FaqSessionRuntime', path: '/faq#session-runtime', component: Faq },
  { name: 'Terms', path: '/terms', component: Terms },
  { name: 'Support', path: '/support', component: Support },
  { name: 'Status', path: '/status', component: Status },
  { name: 'Slack', path: '/slack', component: Slack },
  { name: 'Contact', path: '/contact', component: Support },
  {
    name: 'KillercodaAccess',
    path: '/killercoda-access',
    component: KillercodaAccess,
  },
  {
    name: 'AccessRequestGeneral',
    path: '/access-request',
    component: AccessRequestGeneral,
  },
  {
    name: 'AccessRequestLinuxFoundation',
    path: '/linuxfoundation/access-request',
    component: AccessRequestLinuxFoundation,
  },
  {
    name: 'AuthenticationLinuxFoundation',
    path: '/linuxfoundation',
    component: AuthenticationLinuxFoundation,
  },
  { name: 'Redirect', path: '/r', component: Redirect },
  {
    name: 'AttendeeView',
    path: '/attendee/:uuid',
    component: AttendeeView,
  },
  {
    name: 'AttendeeSolution',
    path: '/attendee/:uuid/content',
    component: Solution,
  },
  {
    name: 'AttendeeSolutionPublicViaClusterUuid',
    path: '/session/solution/:uuid',
    component: SolutionPublicViaClusterUuid,
  },
  {
    name: 'AttendeeScore',
    path: '/attendee/:uuid/score',
    component: Score,
  },
  {
    name: 'AttendeeScorePublicViaClusterUuid',
    path: '/session/score/:uuid',
    component: ScorePublicViaClusterUuid,
  },
  {
    name: 'CoursePreview',
    path: '/course/preview/:uuid',
    component: CoursePreview,
  },
  {
    name: 'CourseTipsAttendee',
    path: '/attendee/:uuid/tips',
    component: CourseTipsAttendee,
  },
  { name: 'UserProfile', path: '/account', component: UserProfile },
  { name: 'UserConsent', path: '/consent', component: UserConsent },
  { name: 'OrderCheckout', path: '/order/checkout', component: OrderCheckout },
  { name: 'OrderSelectCreate', path: '/order', component: OrderSelectCreate },
];
