<template>
  <div>
    <h1>Dashboard</h1>
    <div v-if="error" class="alert alert-danger">An error occurred</div>
    <Info></Info>
    <span v-if="attendeesActive().length">
      <h2 class="category-header">Active</h2>
      <ul class="attendee-records">
        <li
          v-for="attendee in attendeesActive()"
          :key="attendee['@id']"
          v-on:click="clickAttendee(attendee)"
        >
          <AttendeeListItem v-bind:attendee="attendee"></AttendeeListItem>
        </li>
      </ul>
      <div class="mt-4"></div>
    </span>
    <span v-if="attendeesNotActivated().length">
      <h2 v-if="showCategoryHeaderNotActivated()" class="category-header">
        Not Activated
      </h2>
      <ul class="attendee-records">
        <li
          v-for="attendee in attendeesNotActivated()"
          :key="attendee['@id']"
          v-on:click="clickAttendee(attendee)"
        >
          <AttendeeListItem v-bind:attendee="attendee"></AttendeeListItem>
        </li>
      </ul>
      <div class="mt-4"></div>
    </span>
    <span v-if="showKillercodaLink()">
      <h2 class="category-header">
        Training Scenarios
      </h2>
      <ul class="attendee-records">
        <li>
          <AttendeeListKillercodaItem></AttendeeListKillercodaItem>
        </li>
      </ul>
      <div class="mt-4"></div>
    </span>
    <span v-if="attendeesCompleted().length">
      <h2 class="category-header">Completed</h2>
      <ul class="attendee-records">
        <li
          v-for="attendee in attendeesCompleted()"
          :key="attendee['@id']"
          v-on:click="clickAttendee(attendee)"
        >
          <AttendeeListItem v-bind:attendee="attendee"></AttendeeListItem>
        </li>
      </ul>
      <div class="mt-4"></div>
    </span>
    <div
      v-if="showButtonShowAllAttendees()"
      class="text-center mt-3"
      style="cursor: pointer"
      @click="showAllAttendees()"
    >
      show old records
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router/router';
import Info from '@/components/dashboard/Info';
import AttendeeListItem from '@/components/attendee/ListItem';
import AttendeeListKillercodaItem from '@/components/attendee/ListKillercodaItem';

export default {
  name: 'AttendeeList',
  components: { AttendeeListItem, Info, AttendeeListKillercodaItem },
  data: function() {
    return {
      attendeeShowMonth: 13,
    };
  },
  computed: {
    ...mapGetters({
      error: 'attendee/list/error',
      attendees: 'attendee/list/data',
      isLoading: 'attendee/list/isLoading',
      user: 'user/list/data',
    }),
  },
  methods: {
    showCategoryHeaderNotActivated: function() {
      return (
        this.attendeesActive().length ||
        this.attendeesCompleted().length ||
        this.showKillercodaLink()
      );
    },
    attendeesActive: function() {
      return this.attendeesFilteredCategory('active');
    },
    attendeesCompleted: function() {
      return this.attendeesFilteredCategory('completed');
    },
    attendeesNotActivated: function() {
      return this.attendeesFilteredCategory('not_activated');
    },
    attendeesFilteredCategory: function(category) {
      return this.attendeesFilteredDate(this.attendeeShowMonth).filter(function(
        attendee
      ) {
        return attendee.category === category;
      });
    },
    attendeesFilteredDate: function(months) {
      if (months === 0) {
        return this.attendees;
      }
      let currentDate = new Date();
      let filterDate = new Date(
        currentDate.setMonth(currentDate.getMonth() - months)
      );
      return this.attendees.filter(function(attendee) {
        return Date.parse(attendee.expiresAtDateString) > filterDate;
      });
    },
    showButtonShowAllAttendees: function() {
      return (
        this.attendeesFilteredDate(this.attendeeShowMonth).length <
        this.attendeesFilteredDate(0).length
      );
    },
    showKillercodaLink: function() {
      return this.user && this.user.showKillercodaLink;
    },
    showAllAttendees: function() {
      this.attendeeShowMonth = 0;
    },
    clickAttendee: function(attendee) {
      if (
        (attendee.terminal && attendee.terminal.expired) ||
        attendee.expired
      ) {
        router.push({
          name: 'AttendeeSolution',
          params: { uuid: attendee.uuid },
        });
      } else {
        router.push({
          name: 'AttendeeView',
          params: { uuid: attendee.uuid },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.attendee-records {
  list-style: none;
  margin: 0;
  padding: 0;
}
.category-header {
  text-align: center;
  background-color: var(--secondary-color);
  color: white !important;
  padding: 5px;
  font-size: 0.8em !important;
  margin-bottom: 0 !important;
}
</style>
