import * as types from './mutation_types';

const showTooManyRequests = ({ commit, state }) => {
  commit(types.SET_SHOW_TOO_MANY_REQUESTS, true);
  commit(types.RAISE_TOO_MANY_REQUESTS_COUNTER);

  setTimeout(function() {
    commit(types.REDUCE_TOO_MANY_REQUESTS_COUNTER);
    if (state.showTooManyRequestsCounter === 0) {
      commit(types.SET_SHOW_TOO_MANY_REQUESTS, false);
    }
  }, 10000);
};

export { showTooManyRequests };
