import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      showTooManyRequests: false,
    });
  },

  [types.SET_SHOW_TOO_MANY_REQUESTS](state, active) {
    Object.assign(state, {
      showTooManyRequests: active,
    });
  },

  [types.RAISE_TOO_MANY_REQUESTS_COUNTER](state) {
    Object.assign(state, {
      showTooManyRequestsCounter: state.showTooManyRequestsCounter + 1,
    });
  },

  [types.REDUCE_TOO_MANY_REQUESTS_COUNTER](state) {
    let newCounter = state.showTooManyRequestsCounter - 1;
    if (newCounter < 0) {
      newCounter = 0;
    }
    Object.assign(state, {
      showTooManyRequestsCounter: newCounter,
    });
  },
};
