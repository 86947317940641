var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.attendee)?_c('div',[_c('div',{staticClass:"content"},[(
        _vm.showScoreLink &&
          (_vm.attendee.courseType === null ||
            (!_vm.attendee.courseType.includes('lfcs') &&
              !_vm.attendee.courseType.includes('lfct')))
      )?_c('p',{staticClass:"small",staticStyle:{"text-align":"left"}},[_vm._v(" You can access this page also inside the Remote Desktop by using the icons on the desktop ")]):_vm._e(),_c('ul',[(
          _vm.showScoreLink &&
            _vm.attendee &&
            _vm.attendee.scores &&
            _vm.attendee.scores.length
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'AttendeeScore' },"target":"_blank"}},[_vm._v(" Score ")])],1):_vm._e(),(
          _vm.attendee.courseType === null ||
            (!_vm.attendee.courseType.includes('lfcs') &&
              !_vm.attendee.courseType.includes('lfct'))
        )?_c('li',[_c('div',{staticClass:"link-anchor",on:{"click":function($event){return _vm.scrollToElement('questions')}}},[_vm._v(" Questions and Answers ")])]):_vm._e(),(
          _vm.attendee.courseType === null ||
            (!_vm.attendee.courseType.includes('lfcs') &&
              !_vm.attendee.courseType.includes('lfct') &&
              _vm.versionHasPreview(_vm.attendee.courseVersion))
        )?_c('li',[_c('div',{staticClass:"link-anchor",on:{"click":function($event){return _vm.scrollToElement('preview')}}},[_vm._v(" Preview Questions and Answers ")])]):_vm._e(),(
          _vm.attendee.courseType === null ||
            (!_vm.attendee.courseType.includes('lfcs') &&
              !_vm.attendee.courseType.includes('lfct'))
        )?_c('li',[_c('div',{staticClass:"link-anchor",on:{"click":function($event){return _vm.scrollToElement('tips')}}},[_vm._v(" Exam Tips ")])]):_vm._e()])]),_c('span',[_c('a',{attrs:{"id":"questions"}}),_c('CourseAnswers',{attrs:{"courseUuid":_vm.courseUuid ? _vm.courseUuid : null,"courseVersion":_vm.attendee.courseVersion,"coursePassed":_vm.course ? _vm.course : null,"fetchCourses":false}}),_c('a',{attrs:{"id":"preview"}}),(_vm.versionHasPreview(_vm.attendee.courseVersion))?_c('CoursePreview',{attrs:{"courseUuid":_vm.courseUuid ? _vm.courseUuid : null,"courseVersion":_vm.attendee.courseVersion,"coursePassed":_vm.course ? _vm.course : null,"fetchCourses":false}}):_vm._e(),_c('a',{attrs:{"id":"tips"}}),_c('CourseTips',{attrs:{"courseUuid":_vm.courseUuid ? _vm.courseUuid : null,"courseVersion":_vm.attendee.courseVersion,"coursePassed":_vm.course ? _vm.course : null,"fetchCourses":false}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }