<template>
  <div v-if="attendee">
    <div class="content">
      <p
        v-if="
          showScoreLink &&
            (attendee.courseType === null ||
              (!attendee.courseType.includes('lfcs') &&
                !attendee.courseType.includes('lfct')))
        "
        style="text-align: left"
        class="small"
      >
        You can access this page also inside the Remote Desktop by using the
        icons on the desktop
      </p>
      <ul>
        <li
          v-if="
            showScoreLink &&
              attendee &&
              attendee.scores &&
              attendee.scores.length
          "
        >
          <router-link :to="{ name: 'AttendeeScore' }" target="_blank">
            Score
          </router-link>
        </li>
        <li
          v-if="
            attendee.courseType === null ||
              (!attendee.courseType.includes('lfcs') &&
                !attendee.courseType.includes('lfct'))
          "
        >
          <div class="link-anchor" v-on:click="scrollToElement('questions')">
            Questions and Answers
          </div>
        </li>
        <li
          v-if="
            attendee.courseType === null ||
              (!attendee.courseType.includes('lfcs') &&
                !attendee.courseType.includes('lfct') &&
                versionHasPreview(attendee.courseVersion))
          "
        >
          <div class="link-anchor" v-on:click="scrollToElement('preview')">
            Preview Questions and Answers
          </div>
        </li>
        <li
          v-if="
            attendee.courseType === null ||
              (!attendee.courseType.includes('lfcs') &&
                !attendee.courseType.includes('lfct'))
          "
        >
          <div class="link-anchor" v-on:click="scrollToElement('tips')">
            Exam Tips
          </div>
        </li>
      </ul>
    </div>

    <span>
      <a id="questions"></a>
      <CourseAnswers
        v-bind:courseUuid="courseUuid ? courseUuid : null"
        v-bind:courseVersion="attendee.courseVersion"
        v-bind:coursePassed="course ? course : null"
        v-bind:fetchCourses="false"
      ></CourseAnswers>

      <a id="preview"></a>
      <CoursePreview
        v-if="versionHasPreview(attendee.courseVersion)"
        v-bind:courseUuid="courseUuid ? courseUuid : null"
        v-bind:courseVersion="attendee.courseVersion"
        v-bind:coursePassed="course ? course : null"
        v-bind:fetchCourses="false"
      ></CoursePreview>

      <a id="tips"></a>
      <CourseTips
        v-bind:courseUuid="courseUuid ? courseUuid : null"
        v-bind:courseVersion="attendee.courseVersion"
        v-bind:coursePassed="course ? course : null"
        v-bind:fetchCourses="false"
      ></CourseTips>
    </span>
  </div>
</template>

<script>
import CourseAnswers from '@/components/course/CourseAnswers';
import CoursePreview from '@/components/course/CoursePreview';
import CourseTips from '@/components/course/CourseTips';
import { mapActions } from 'vuex';

export default {
  components: {
    CourseAnswers,
    CoursePreview,
    CourseTips,
  },
  props: {
    showScoreLink: Boolean,
    attendee: Object,
    courseVersion: String,
    // either pass courseUuid
    courseUuid: String,
    // OR course
    course: Object,
  },
  mounted: function() {
    if (!this.course) {
      this.getCourses();
    }
  },
  methods: {
    ...mapActions({
      getCourses: 'course/list/getCourses',
    }),
    scrollToElement(id) {
      const element = document.getElementById(id);
      element.scrollIntoView(true);
    },
    versionHasPreview(version) {
      if (version.includes('-A') || !version.includes('-')) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  background-color: white;
  min-height: 100vh;
}
.content {
  max-width: 860px;
  margin: 0 auto;
  padding-top: 50px;
}
.link-anchor {
  cursor: pointer;
}
.link-anchor {
  cursor: pointer;
  color: #343a40;
}
a:link,
a:visited,
a:hover,
a:active {
  color: #343a40;
}
</style>
