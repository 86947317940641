<template>
  <div class="wrapper">
    <div v-if="!isAnyLoading()">
      <div v-if="!featurePurchaseEnabled()">
        <p class="alert alert-danger">
          New purchases are temporarily disabled, please try again later.
        </p>
      </div>
      <div v-else>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <DashboardPreviewButtons></DashboardPreviewButtons>
        <div style="text-align: center; margin-top: 50px">
          <router-link :to="{ name: 'Pricing' }">
            <strong>Pricing</strong>
          </router-link>
        </div>
        <div class="product-list">
          <b-row>
            <b-col
              md="6"
              sm="12"
              v-for="product in products"
              v-bind:key="product.productId"
              v-if="product.productId === 'cka_simulator_2x'"
            >
              <div class="item" v-on:click="createOrder(product)">
                <span>
                  <span><strong>CKA Simulator</strong></span>
                  <p>
                    <small>Kubernetes {{ product.courseVersion }}</small>
                  </p>
                  <b-img
                    src="@/assets/k8s_cka_logo_s.png"
                    fluid
                    width="100px"
                  ></b-img>
                </span>
              </div>
            </b-col>
            <b-col
              md="6"
              sm="12"
              v-for="product in products"
              v-bind:key="product.productId"
              v-if="product.productId === 'ckad_simulator_2x'"
            >
              <div class="item" v-on:click="createOrder(product)">
                <span>
                  <span><strong>CKAD Simulator</strong></span>
                  <p>
                    <small
                      >Kubernetes
                      {{ removeLetters(product.courseVersion) }}</small
                    >
                  </p>
                  <b-img
                    src="@/assets/k8s_ckad_logo_s.png"
                    fluid
                    width="100px"
                  ></b-img>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
              sm="12"
              v-for="product in products"
              v-bind:key="product.productId"
              v-if="product.productId === 'cks_simulator_2x'"
            >
              <div class="item" v-on:click="createOrder(product)">
                <span>
                  <span><strong>CKS Simulator</strong></span>
                  <p>
                    <small>Kubernetes {{ product.courseVersion }}</small>
                  </p>
                  <b-img
                    src="@/assets/k8s_cks_logo_s.png"
                    fluid
                    width="100px"
                  ></b-img>
                </span>
              </div>
            </b-col>
            <b-col md="6" sm="12">
              <div class="item" v-on:click="orderCksCourse()">
                <span>
                  <span><strong>CKS Full Preparation Course</strong></span>
                  <br /><br />
                  <b-img
                    src="@/assets/cks_course_logo.png"
                    fluid
                    style="max-width:200px"
                  ></b-img>
                </span>
              </div>
            </b-col>
            <b-col
              md="6"
              sm="12"
              v-for="product in products"
              v-bind:key="product.productId"
              v-if="product.productId === 'lfcs_simulator_2x'"
            >
              <div class="item" v-on:click="createOrder(product)">
                <span>
                  <span><strong>LFCS Simulator</strong></span>
                  <p></p>
                  <b-img
                    src="@/assets/lfcs_logo.png"
                    fluid
                    style="max-width:100px"
                  ></b-img>
                </span>
              </div>
            </b-col>
            <b-col
              md="6"
              sm="12"
              v-for="product in products"
              v-bind:key="product.productId"
              v-if="product.productId === 'lfct_simulator_2x'"
            >
              <div class="item" v-on:click="createOrder(product)">
                <span>
                  <span><strong>LFCT Simulator</strong></span>
                  <p></p>
                  <b-img
                    src="@/assets/lfct_logo.png"
                    fluid
                    style="max-width:100px"
                  ></b-img>
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <Spinner></Spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import DashboardPreviewButtons from '@/components/dashboard/DashboardPreviewButtons';

export default {
  components: {
    Spinner,
    DashboardPreviewButtons,
  },
  computed: {
    ...mapGetters({
      featureIsLoading: 'feature/list/isLoading',
      isLoading: 'product/list/isLoading',
      error: 'product/list/error',
      products: 'product/list/data',
      user: 'user/list/data',
      userIsLoading: 'user/list/isLoading',
    }),
  },
  methods: {
    ...mapActions({
      getProducts: 'product/list/getProducts',
      getFeatures: 'feature/list/getFeatures',
      setProduct: 'order/selection/setProduct',
    }),
    removeLetters: function(string) {
      string = string.replace('a', '');
      string = string.replace('b', '');
      return string.replace('c', '');
    },
    isAnyLoading: function() {
      return this.featureIsLoading || this.userIsLoading;
    },
    orderCksCourse() {
      window.open('https://killer.sh/r?d=cks-course', '_blank');
    },
    createOrder(product) {
      this.setProduct(product);
    },
    featurePurchaseEnabled() {
      let func = this.$store.getters['feature/list/featureByName'];
      if (func('purchase')) {
        return func('purchase')['enabled'];
      }
      return false;
    },
  },
  created() {
    this.getProducts();
    this.getFeatures();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_global.scss';
.product-list {
  text-align: center;
  .item {
    border: 1px solid grey;
    padding: 20px 0;
    height: 200px;
    margin: 30px 0 0 0;
    overflow: hidden;
  }
}
ul {
  padding: 0;
  list-style: none;
  li {
    padding: 50px 0 0 0;
    div {
      border: 1px solid grey;
      padding: 30px 0;
    }
  }
}
.item {
  cursor: pointer;
}
</style>
