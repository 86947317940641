import { render, staticRenderFns } from "./ScoreCheckmarks.vue?vue&type=template&id=58ec1cc0&scoped=true"
import script from "./ScoreCheckmarks.vue?vue&type=script&lang=js"
export * from "./ScoreCheckmarks.vue?vue&type=script&lang=js"
import style0 from "./ScoreCheckmarks.vue?vue&type=style&index=0&id=58ec1cc0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ec1cc0",
  null
  
)

export default component.exports