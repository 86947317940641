import VueRouter from 'vue-router';
import Vue from 'vue';
import routes from './routes';
import store from '../store';
import VueCookies from 'vue-cookies';
import * as definitions from '@/store/modules/authentication/status/definitions';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [...routes],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});

let routeNeedsAuthentication = [
  'Dashboard',
  'AttendeeView',
  'UserProfile',
  'UserConsent',
  'CoursePreview',
  'AttendeeSolution',
  'AttendeeScore',
  'CourseTipsAttendee',
  'OrderCheckout',
  'OrderSelectCreate',
  'AccessRequestGeneral',
  'AccessRequestLinuxFoundation',
  'Support',
  'Contact',
  'Status',
  'KillercodaAccess',
];

function handleRouterBeforeEach(to, from, next) {
  let isAuthenticating =
    store.getters['authentication/status/isAuthenticating'];
  if (isAuthenticating) {
    setTimeout(function() {
      handleRouterBeforeEach(to, from, next);
    }, 50);
    return;
  }

  let isAuthenticated = store.state.authentication.status.isAuthenticated;
  let needsAuthentication = routeNeedsAuthentication.indexOf(to.name) !== -1;
  if (!isAuthenticated && needsAuthentication) {
    VueCookies.set(
      definitions.COOKIE_NAME_AUTH_REDIRECT_URL,
      to.fullPath,
      '1h'
    );
    if (to.name === 'AccessRequestLinuxFoundation') {
      next({
        name: 'AuthenticationLinuxFoundation',
        query: to.query,
        params: { prevRoute: to },
      });
    } else {
      next({ name: 'Login', params: { prevRoute: to } });
    }
    return;
  }

  store.dispatch(
    'status/maintenance/checkCurrentPageShouldShowMessage',
    to.name
  );
  next();
}

router.beforeEach((to, from, next) => {
  handleRouterBeforeEach(to, from, next);
});

export default router;
