<template>
  <div>
    <span v-if="getListError()" class="connection-error">
      Connection errors occurred, please check your internet connection
    </span>
    <div v-if="getIsLoadingFirstTime">
      <div class="wrapper">
        <Spinner></Spinner>
      </div>
    </div>
    <div v-if="attendee">
      <div v-if="isNotActivated()">
        <AttendeeActivate v-bind:attendee="attendee"></AttendeeActivate>
      </div>

      <div v-if="isActive()">
        <div v-if="showAttendeeResumeNotPossibleMessage()" class="wrapper">
          <p class="alert alert-info">
            Session resuming is not possible atm, we're working on it. Please
            check back a bit later.<br />
            <router-link :to="{ name: 'Status' }">
              https://killer.sh/status
            </router-link>
          </p>
        </div>
        <div v-else>
          <AttendeeRun v-bind:attendee="attendee"></AttendeeRun>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AttendeeActivate from './activate/Activate.vue';
import AttendeeRun from './run/Run';
import Spinner from '@/components/util/Spinner';

export default {
  components: {
    AttendeeActivate,
    AttendeeRun,
    Spinner,
  },
  data: function() {
    return {
      uuid: this.$route.params.uuid,
      featureListLoaded: false,
      getIsLoadingFirstTime: true,
    };
  },
  computed: {
    ...mapGetters({
      getIsLoading: 'attendee/get/isLoading',
      getErrorCounter: 'attendee/get/errorCounter',
      attendee: 'attendee/get/data',
    }),
  },
  watch: {
    getIsLoading(newValue) {
      if (!newValue) {
        this.getIsLoadingFirstTime = false;
      }
    },
  },
  created() {
    this.$store.dispatch('attendee/get/resetAction');
    this.getAttendee();
    this.getFeatures();
  },
  methods: {
    ...mapActions({
      getFeatures: 'feature/list/getFeatures',
    }),
    getAttendee: function() {
      this.$store.dispatch('attendee/get/getAttendee', [this.uuid, true]);
    },
    isNotActivated: function() {
      return this.attendee.status === 'not_activated';
    },
    isActive: function() {
      return this.attendee.status === 'active';
    },
    getListError: function() {
      return this.getErrorCounter >= 3;
    },
    showAttendeeResumeNotPossibleMessage() {
      return (
        (this.attendee.terminal.status === 'resume' ||
          this.attendee.terminal.status === 'suspend') &&
        (this.featureAttendeeResumeEnabled() === false ||
          this.attendee.ableToBeResumed === false)
      );
    },
    featureAttendeeResumeEnabled() {
      let func = this.$store.getters['feature/list/featureByName'];
      if (func('cluster_resume')) {
        return func('cluster_resume')['enabled'];
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';

.connection-error {
  position: absolute;
  background-color: darkred;
  color: white;
  padding: 5px;
}
</style>
