<template>
  <div class="wrapper">
    <div class="text-center">
      <h1>Activate {{ attendee.courseNameDisplay }} Session</h1>
    </div>
    <br />
    <Spinner v-if="userIsLoading"></Spinner>
    <div v-else-if="!userHasGoodProfile()">
      <div class="alert alert-info">
        We were not able to geolocate you, please provide your address before
        you can start a session. This is necessary because we create the
        simulator environment closest to your location for low latency.
      </div>
      <UserProfileEdit v-bind:userPassed="user"></UserProfileEdit>
    </div>
    <div v-else>
      <div class="text-center">
        <b-img
          src="@/assets/k8s_cks_logo_s.png"
          fluid
          width="200px"
          v-if="attendee.course.type === 'cks_simulator'"
        ></b-img>
        <b-img
          src="@/assets/k8s_cka_logo_s.png"
          fluid
          width="200px"
          v-if="attendee.course.type === 'cka_simulator'"
        ></b-img>
        <b-img
          src="@/assets/k8s_ckad_logo_s.png"
          fluid
          width="200px"
          v-if="attendee.course.type === 'ckad_simulator'"
        ></b-img>
        <b-img
          src="@/assets/lfct_logo.png"
          fluid
          style="max-width: 150px"
          v-if="attendee.course.type === 'lfct_simulator'"
        ></b-img>
        <b-img
          src="@/assets/lfcs_logo.png"
          fluid
          style="max-width: 150px"
          v-if="attendee.course.type === 'lfcs_simulator'"
        ></b-img>
      </div>

      <br /><br /><br />
      <div>
        <h5>Activation</h5>
        <p>
          Once you activate this session it'll take a few minutes to create your
          environment.
        </p>

        <br />
        <h5>Access for the next 36hrs</h5>
        <p>
          You'll have access to your environment
          <strong>during the next following 36hrs</strong>, starting the moment
          your environment is ready. This window is time based and not usage
          based. You'll always have access to the questions and solutions.
        </p>

        <br />
        <h5>Browser reload</h5>
        <p>
          For the 36hrs your session will be kept running in the background. You
          can close the window or even use a different browser without losing
          changes.
        </p>

        <br />
        <h5>Solutions</h5>
        <p>
          When the countdown reaches 0 (or when you stop it earlier) you'll be
          able to see the proposed solutions for each question. Try to solve as
          many questions by yourself as fast as possible for the best learning
          effect.
        </p>

        <br />
        <h5>Content</h5>
        <p>
          All simulator sessions of the same type (CKS|CKA|CKAD|LFCT|LFCS) will
          have identical content/scenarios.
        </p>

        <br />
        <h5>Restart</h5>
        <p>
          While in the 36hrs access window you can restart the environment as
          often as you like.
          <span v-if="attendee.course.type !== 'lfct_simulator'"
            >This might be necessary in the CKS|CKA where you can mess things up
            properly.</span
          >
        </p>

        <br />
        <h5>Support</h5>
        <p>
          <a href="https://killer.sh/slack" target="_blank">Slack</a> for
          scenario discussions
          <br />
          <router-link :to="{ name: 'Support' }">Support</router-link> for
          session/account issues
        </p>

        <br />
        <h5>Usage</h5>
        <p>
          You're only allowed to use your environment for the purpose of exam
          preparation and question solving. You agree not to perform intentional
          harmful or exploitative activity against killer.sh or others.
        </p>

        <br />
        <h5>Checklist</h5>
        <p>
          <small
            >If you experience any kind of issues please make sure all points
            here are complied with:</small
          >
        </p>
        <ul>
          <li>
            <strong>Browser</strong>: only latest Chrome and latest Firefox are
            supported
          </li>
          <li>
            <strong>Extensions</strong>: disable ALL extensions/plugins and run
            in private mode
          </li>
          <li><strong>VPN/Proxy</strong>: don't use a VPN/Proxy</li>
          <li>
            <strong>Internet</strong>: use a stable internet connection, with
            low usage by others
          </li>
          <li
            v-if="
              attendee.course.type !== 'lfcs_simulator' &&
                attendee.course.type !== 'lfct_simulator'
            "
          >
            <strong>Tips</strong>: Make sure to read the
            <router-link
              :to="{
                name: 'CourseTipsAttendee',
                params: { uuid: attendee.uuid },
              }"
              target="_blank"
            >
              <strong>Tips</strong>
            </router-link>
          </li>
        </ul>

        <br />
        <div v-if="attendee.course.type.includes('lfcs')">
          <h5><strong>Remote Desktop</strong></h5>
          <p>
            Since 2022 the Exam UI is using a Remote Desktop (XFCE) instead of a
            Remote Terminal and the Linux Foundation released an
            <a
              href="https://training.linuxfoundation.org/blog/update-on-certification-exam-proctoring-migration/?utm_source=lftraining&utm_medium=twitter&utm_campaign=blog"
              target="_blank"
              >update</a
            >
            about this. Coming from OSX/Windows there will be changes in
            copy&paste for example. This simulator provides a very similar
            Remote Desktop environment.
          </p>

          <p>
            There are still differences between the real exams and the
            simulators, like:
          </p>

          <ul>
            <li>
              You'll need to use the PSI Secure Browser to access the real exams
              whereas you can use Chrome/Firefox to access the simulators
            </li>
            <li>
              In the simulators you can use the top navigation Exam Interface to
              switch back to the old Remote Terminal in case of issues, this
              isn't possible in the real exams
            </li>
          </ul>
        </div>
        <div v-else-if="!attendee.course.type.includes('lfct')">
          <h5><strong>Remote Desktop</strong></h5>
          <p>
            Since 2022 the Exam UI is using a Remote Desktop (XFCE) instead of a
            Remote Terminal and the Linux Foundation released an
            <a
              href="https://training.linuxfoundation.org/blog/update-on-certification-exam-proctoring-migration/?utm_source=lftraining&utm_medium=twitter&utm_campaign=blog"
              target="_blank"
              >update</a
            >
            about this. Coming from OSX/Windows there will be changes in
            copy&paste for example. This simulator provides a very similar
            Remote Desktop environment.
          </p>

          <p>
            There are still differences between the real exams and the
            simulators, like:
          </p>

          <ul>
            <li>
              You'll need to use the PSI Secure Browser to access the real exams
              whereas you can use Chrome/Firefox to access the simulators
            </li>
            <li>
              You can only access certain allowed URLs in the Remote Desktop's
              Firefox of the real exams whereas there are no such restrictions
              in the simulators
            </li>
            <li>
              In the simulators you can use the top navigation Exam Interface to
              switch back to the old Remote Terminal in case of issues, this
              isn't possible in the real exams
            </li>
            <li
              v-if="
                (attendee.courseType.includes('ckad') &&
                  attendee.courseVersion !== '1.30') ||
                  (attendee.courseType.includes('cks') &&
                    attendee.courseVersion !== '1.30') ||
                  (attendee.courseType.includes('cka') &&
                    attendee.courseVersion !== '1.31')
              "
            >
              In the real exam each question will be solved on a different
              instance whereas in the simulator multiple questions will be
              solved on the same instances
            </li>
          </ul>
        </div>

        <div class="action-links">
          <div class="row">
            <div
              v-if="anotherAttendeeActivated()"
              class="col-sm-12 alert alert-warning"
            >
              Another simulator session is already running! Head back to your
              dashboard to select the active one.
            </div>
            <div
              v-if="activationChoicesShowPreviousActivations()"
              class="col-sm-12 alert alert-info"
            >
              Once version A or B has been selected it can't be changed anymore
              and will remain the same for the 36 hours access.
            </div>
          </div>

          <div
            class="row"
            v-if="
              activateError ||
                !canBeActivated() ||
                !featureAttendeeActivateEnabled
            "
          >
            <div class="col-sm-12 alert alert-danger">
              Session activation is not possible atm, we're working on it.
              Please check back a bit later.<br />
              <router-link :to="{ name: 'Status' }">
                https://killer.sh/status
              </router-link>
            </div>
            <div class="col-sm-12 mt-5">
              <router-link
                :to="{ name: 'Dashboard' }"
                class="btn card-cta dashboard"
              >
                Dashboard
              </router-link>
            </div>
          </div>

          <div
            class="row"
            v-else-if="
              featureAttendeeActivateEnabled === null || activateIsLoading
            "
          >
            <div class="col">
              <div class="activatingMessage">
                <Spinner></Spinner>
              </div>
            </div>
          </div>

          <div
            class="row"
            v-else-if="
              attendee.courseVersionChoices &&
                attendee.courseVersionChoices.length > 1
            "
          >
            <div class="col-sm-12">
              <div class="row">
                <div class="col-6">
                  <ActivateVersionChoice
                    :courseVersionChoices="attendee.courseVersionChoices[0]"
                    :activateAttendee="activateAttendee"
                    :showPreviousActivations="
                      activationChoicesShowPreviousActivations()
                    "
                  ></ActivateVersionChoice>
                </div>
                <div class="col-6">
                  <ActivateVersionChoice
                    :courseVersionChoices="attendee.courseVersionChoices[1]"
                    :activateAttendee="activateAttendee"
                    :showPreviousActivations="
                      activationChoicesShowPreviousActivations()
                    "
                    classOuter="pull-right"
                  ></ActivateVersionChoice>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-5">
              <router-link
                :to="{ name: 'Dashboard' }"
                class="btn card-cta dashboard"
              >
                Dashboard
              </router-link>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col-md-6 col-sm-12">
              <router-link
                :to="{ name: 'Dashboard' }"
                class="btn card-cta dashboard"
              >
                Dashboard
              </router-link>
            </div>
            <div class="col-md-6 col-sm-12">
              <button
                class="btn card-cta-primary pull-right"
                v-on:click="activateAttendee()"
              >
                Activate Session
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/util/Spinner';
import UserProfileEdit from '@/components/user/AccountEditForm';
import ActivateVersionChoice from './ActivateVersionChoice.vue';

export default {
  components: {
    Spinner,
    UserProfileEdit,
    ActivateVersionChoice,
  },
  name: 'AttendeeActivate',
  props: {
    attendee: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      activateIsLoading: 'attendee/activate/isLoading',
      activateError: 'attendee/activate/error',
      user: 'user/list/data',
      userIsLoading: 'user/list/isLoading',
    }),
    featureAttendeeActivateEnabled() {
      let func = this.$store.getters['feature/list/featureByName'];
      if (func('attendee_activate')) {
        return func('attendee_activate')['enabled'];
      }
      return null;
    },
  },
  data: function() {
    return {
      activateClicked: false,
    };
  },
  methods: {
    ...mapActions({
      getFeatures: 'feature/list/getFeatures',
    }),
    userHasGoodProfile: function() {
      return this.user && this.user.providedAllDataToStartSession === true;
    },
    showActivateButton: function() {
      return (
        !this.activateIsLoading &&
        this.canBeActivated() &&
        !this.activateClicked
      );
    },
    dispatchActivateAttendeeEvent: function(version) {
      this.activateClicked = true;
      this.$store.dispatch('attendee/activate/activate', [
        this.attendee['@id'],
        this.attendee['uuid'],
        version,
      ]);
    },
    activateAttendee: function(version) {
      if (this.anotherAttendeeActivated()) {
        let options = {
          html: true, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
          loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
          reverse: false, // switch the button positions (left to right, and vise versa)
          okText: 'Yes',
          cancelText: 'No',
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          type: 'soft', // coming soon: 'soft', 'hard'
          verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
          verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
          clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
          backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
          customClass: '', // Custom class to be injected into the parent node for the current dialog instance
        };
        let that = this;
        this.$dialog
          .confirm(
            '<img src="/icons/warn.svg" width="30px" style="margin: 3px 20px 3px 3px"><strong>Activation Warning</strong><br><br>Another simulator session is already running!<br><br>All simulator sessions of the same type (CKS|CKA|CKAD|LFCS|LFCT) have the same content and questions.<br><br>Do you want to activate this one simultaneously?<br><br>',
            options
          )
          .then(function() {
            that.dispatchActivateAttendeeEvent(version);
          })
          .catch(function() {});
      } else {
        let options = {
          html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
          loader: false, // set to true if you want the dialog to show a loader after click on "proceed"
          reverse: false, // switch the button positions (left to right, and vise versa)
          okText: 'Yes',
          cancelText: 'No',
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          type: 'soft', // coming soon: 'soft', 'hard'
          verification: 'very much so', // for hard confirm, user will be prompted to type this to enable the proceed button
          verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
          clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
          backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
          customClass: '', // Custom class to be injected into the parent node for the current dialog instance
        };

        let that = this;
        this.$dialog
          .confirm(
            'Did you read the complete info text? Are you ready to start your test session?',
            options
          )
          .then(function() {
            that.dispatchActivateAttendeeEvent(version);
          })
          .catch(function() {});
      }
    },
    canBeActivated: function() {
      return this.attendee.ableToBeActivated;
    },
    anotherAttendeeActivated: function() {
      return this.attendee['anotherAttendeeActivated'];
    },
    activationChoicesShowPreviousActivations: function() {
      return (
        this.attendee !== null &&
        this.attendee.courseVersionChoices !== null &&
        Array.isArray(this.attendee.courseVersionChoices) &&
        this.attendee.courseVersionChoices.length >= 2 &&
        (this.attendee.courseVersionChoices[0][1].length > 0 ||
          this.attendee.courseVersionChoices[1][1].length > 0)
      );
    },
  },
  created() {
    if (!this.userHasGoodProfile()) {
      this.$store.dispatch('user/list/getUserObject');
    }
    this.getFeatures();
    this.$store.dispatch('attendee/activate/clearError');
  },
};
</script>

<style lang="scss" scoped="true">
@import '@/styles/_global.scss';
.dashboard {
  width: fit-content;
}
.activatingMessage {
  padding-top: 30px;
}
</style>
