import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import store from '@/store';
import Vue from 'vue';

const activate = ({ commit }, dataPassed) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_ERROR, false);
  let data = {
    attendee: dataPassed[0],
    version: dataPassed[2],
  };
  let attendeeUuid = dataPassed[1];
  let options = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  fetch('attendee/activate', options)
    .then(response => {
      return response.json();
    })
    .then(data => {
      commit(types.TOGGLE_LOADING);
      if (data.success === true) {
        store.dispatch('attendee/get/getAttendee', [attendeeUuid, true]);
      } else {
        commit(types.SET_ERROR, 'error');
        Vue.prototype.$flashStorage.flash(
          'Could not activate session',
          'error',
          {
            timeout: 5000,
          }
        );
      }
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

const clearError = ({ commit }) => {
  commit(types.SET_ERROR, false);
};

export { activate, clearError };
