import { render, staticRenderFns } from "./CoursePreview.vue?vue&type=template&id=519d8baa&scoped=true"
import script from "./CoursePreview.vue?vue&type=script&lang=js"
export * from "./CoursePreview.vue?vue&type=script&lang=js"
import style0 from "./CoursePreview.vue?vue&type=style&index=0&id=519d8baa&prod&lang=css"
import style1 from "./CoursePreview.vue?vue&type=style&index=1&id=519d8baa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519d8baa",
  null
  
)

export default component.exports